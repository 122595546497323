import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"

const IndexPage = () => {
  return (
    <Layout>
      {process.env.GATSBY_STORYBLOK_FOLDER_WHITELIST.split(",").map(
        (folder) => (
          <p>
            <a
              href={`/${process.env.GATSBY_GLOBAL_PATH_PREFIX}-${folder}`}
            >{`/${process.env.GATSBY_GLOBAL_PATH_PREFIX}-${folder}`}</a>
          </p>
        )
      )}
    </Layout>
  )
}

export default IndexPage
